export const getMobilePlatform = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  if (/android/i.test(userAgent)) return 'android'
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return 'ios'
  return ''
}

export const isStandaloneApp = () => {
  return window.matchMedia('(display-mode: standalone)').matches
}
