import { getMobilePlatform, isStandaloneApp } from '../helpers/getMobilePlatform'
import { setCookie } from '../shared/cookies'

export default class Notifications {
  constructor() {
    this.scope = document.querySelector('.notification-overlay')
    if (!this.scope) return

    const platform = getMobilePlatform()
    if (platform) this.scope.classList.add(platform)

    this.bindEvents()
  }

  bindEvents() {
    this.closeButton.addEventListener('click', this.hideSuggestion.bind(this))
  }

  hideSuggestion() {
    this.scope.classList.remove('android', 'ios', 'standalone-suggest')
    if (isStandaloneApp()) setCookie('webpush_standalone', 'hidden', 30)
  }

  get closeButton() {
    return this.scope?.querySelector('.js-close-suggestion')
  }
}
